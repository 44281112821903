<template>
  <v-container>
    <v-sheet class="mx-auto" width="80%">
      <v-subheader>サービス</v-subheader>

      <v-badge content="Beta" offset-x="50" offset-y="50">
        <v-img src="/img/icons/logo_transparent.png" width="250" />
      </v-badge>
      <h2>what is siteshot.link?</h2>
      <div class="text-caption">
        <p class="mt-4">
          <strong>siteshot.link</strong
          >は対象となるサイトや画像、テキストファイルからスクリーンショット、色分析、人物抽出など解析を行い「ショット」として保存します。
        </p>
        <p>
          作成されたショットは制作者のコメント、タグや評価などのメタ情報をつけ保存でき、公開設定で一般公開も可能です。
        </p>
        <v-alert border="top" colored-border type="warning" elevation="2">
          <span class="text-caption">
            ショット作成時にセキュリティ情報付きのURLが発行されますが、このURLを知っている人ならどなたでも「作成者」として編集可能になりますので、作成者以外の方に公開しないようにしてください。
          </span>
        </v-alert>

        <h2 class="mt-6 my-6">LINEアプリ</h2>
        <v-btn
          dark
          color="#06c755"
          href="https://line.me/R/ti/p/%40611jbalh"
          target="_blank"
        >
          talk
        </v-btn>
        から登録できます。
        <p class="mt-2">
          <strong>siteshot.link</strong
          >のLINEアプリとフレンド登録すると、LINEのTALKにこのサイトの「SHOT!」と同じようにURLや画像ファイルを送ると、解析結果の
          <strong>siteshot.link</strong>のURLを返してくれます!
        </p>
        <!-- <v-img src="/resources/lines/611jbalh.png" width="150" /> -->

        <p class="mt-4">
          <v-alert border="top" colored-border type="info" elevation="2">
            <span class="text-caption">
              2022/12/23 <br />
              現在ベータリリース中のため、アクセス制限などたくさん入れて想定外のアクセス対策を入れています。しばらく運用して問題なさそうなら順次緩和して行こうと思っています。また未公開でしばらく更新のないショットは自動的に削除するようにしています。
            </span>
          </v-alert>
        </p>
      </div>

      <h2>価格等</h2>
      <div class="text-caption">
        <p class="mt-4">準備中</p>
        <!-- <Price /> -->
      </div>

      <v-spacer class="my-10" />

      <h2>FAQs</h2>
      <div class="text-caption">
        <p class="mt-4">準備中</p>
        <!--
      <v-subheader class="text-h4">FAQs</v-subheader>
      <Faq />
-->
      </div>
      <v-spacer class="my-10" />

      <h2>リリースノート</h2>

      <Release />
    </v-sheet>
  </v-container>
</template>

<script>
// import Price from "./Prices/Price";
// import Faq from "./Faqs/Faq";
import Release from "./Releases/Release";
export default {
  name: "Home",
  components: {
    // Price,
    // Faq,
    Release,
  },

  computed: {},

  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  async mounted() {
    await this.onLoadAsync();
  },
};
</script>
